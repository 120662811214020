import React from 'react';
import { Layout,Col,Row,Typography,Card } from 'antd';
import IncorporationImage from '../../assets/Incorporation.jpg';

import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;
const Incorporations = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Incorporation:</Title>
      <div className="matter-container">
      <Row gutter={[16, 16]}>
      <Col xs={24} md={8}>
          <img src={IncorporationImage} alt="Utkarsh Tewari" style={{ height: '100%',width:'100%', borderRadius: '8px' }} />
        </Col>
        <Col xs={24} md={16}>
            <Card>
              <Title level={4}>Incorporation:</Title>
              <Paragraph>
                <span className='content-sub-minititle-withoutbg-different-textcolor'>Starting a solo venture or revamping your business? </span>
                We simplify the process. As your Toronto small business lawyer, we'll create a personalized plan to fit your exact needs, avoiding unnecessary complexities. we'll guide you smoothly through incorporating your business, ensuring everything is legally sound.
              </Paragraph>
              <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Our incorporation services include:</span>              
              </Paragraph>
              <Row gutter={[16,16]}>
                <Col xs={24} md={8}>
                <ul style={{ paddingTop: '10px' }}>
                <li>Conducting Corporate Name Searches</li>
                <li>Drafting Articles & Certificate of Incorporation</li>
                <li>Crafting Corporate By-Laws</li>
              </ul>
                </Col>
                <Col xs={24} md={8}>
                <ul style={{ paddingTop: '10px' }}>                
                <li>Digital Minute Book creation</li>
                <li>Issuance of Share Subscription & Share Certificates</li>
                <li>Resolutions for Directors & Shareholders</li>
              </ul>
                </Col>
                <Col xs={24} md={8}>
                <ul style={{ paddingTop: '10px' }}>
                <li>Maintenance of Registers & Ledgers</li>
                <li>Establishing Business Number/Tax Accounts</li>
                <li>Submission of Form 1 & Form 2 Initial Returns etc.</li>
              </ul>
                </Col>
              </Row>
              
            </Card>
            </Col>
            
        </Row>
      </div>
  </Layout>
);

export default Incorporations;
