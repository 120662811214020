import React, { useEffect} from 'react';
import { Layout } from 'antd';
import IncorporationSection from './businessLaw/Incorporations';
import Franchising from './businessLaw/Franchising';
import Licensing from './businessLaw/Licensing';
import ContractNegotiation from './businessLaw/ContractNegotiation';
import Partnership from './businessLaw/Partnership';
import BusinessPurchase from './businessLaw/BusinessPurchase';
import CommercialLease from './businessLaw/CommercialLease';
import EmploymentContracts from './businessLaw/EmploymentContracts';
import Trademark from './businessLaw/Trademark';
import Confidentiality from './businessLaw/Confidentiality';
import ServiceSupply from './businessLaw/ServiceSupply';
import Banner from '../components/Common/Banner';
import BannerImage from '../assets/homebanner.jpg';
import '../styles/CommonStyle.css';

const { Content } = Layout;

const BusinessLaw = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Layout>
      {/* Banner Section */}
      <Banner
        backgroundImage={BannerImage}
        title="Business Law"
        subTitle="Cut through Ontario's business law maze with ease. We'll guide you."
        secondSubtitle="As your trusted business lawyer, we provide expert advice and personalized solutions to navigate any legal hurdle. Don't wait, contact us today to unlock your business potential."
      />
      <Content className='mainpage-content'>
        <div id="incorporations">
          <IncorporationSection />
        </div>
        <div id="licensing">
          <Licensing />
        </div>
        <div id="commercial-lease">
          <CommercialLease />
        </div>
        <div id="contract-negotiation">
          <ContractNegotiation />
        </div>
        <div id="partnership">
          <Partnership />
        </div>
        <div id="business-purchase">
          <BusinessPurchase />
        </div>
        <div id="employment-contracts">
          <EmploymentContracts />
        </div>
        <div id="trademark">
          <Trademark />
        </div>
        <div id="confidentiality">
          <Confidentiality />
        </div> 
        <div id="franchising">
          <Franchising />
        </div>
        <div id="service-supply">
          <ServiceSupply />
        </div>
       
      </Content>
    </Layout>
  );
};

export default BusinessLaw;
