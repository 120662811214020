import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { FileTextOutlined, UserOutlined, EnvironmentOutlined } from '@ant-design/icons';
import PowerOfAttorneyImage from '../../assets/banner.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const PowerOfAttorney = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Power of Attorney:</Title>
    <div className="matter-container">
    <Row gutter={[16,16]}>
    <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Empower Your Future: Secure Your Decisions with Power of Attorney</Title>
            <Paragraph>
              Planning for life's uncertainties gives you peace of mind. We'll help you create a Power of Attorney that accurately reflects your wishes for both property and personal care.
            </Paragraph>
            <Paragraph>
              My expertise ensures:
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Clear Decision-Making:</span> You choose who makes decisions, when, and how.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Protected Wishes:</span> Your voice is heard, even if you can't speak for yourself.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'> Compassionate Guidance:</span> We'll navigate the process with understanding and support.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on living your life,</span> we'll handle the legalese. With our experience in Power of Attorney law, you can plan for the future with confidence and clarity.
            </Paragraph>
            
            
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <img src={PowerOfAttorneyImage} alt="Power of Attorney" style={{width: '100%' }} />
        </Col>
      </Row>
      <Card>
            <Row gutter={[16,16]}>
            <Col xs={24} sm={12} md={8}>
                <Card title={<><FileTextOutlined /> Expert Guidance</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Expert guidance in drafting power of attorney documents for both property and personal care.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><UserOutlined /> Decision-Making</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Ensuring decision-making authority is clearly defined and legally binding.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><EnvironmentOutlined /> Tailoring Documents</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Tailoring documents to meet your specific needs and preferences.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><UserOutlined /> Appointing Agents</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Providing guidance on selecting and appointing trusted agents or attorneys.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><FileTextOutlined /> Document Authentication</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Assisting with the authentication of power of attorney documents.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><EnvironmentOutlined /> Healthcare Decisions</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Offering advice on healthcare decisions and medical treatment under power of attorney for personal care.</Paragraph>
                </Card>
              </Col>
            </Row>
            </Card>
    </div>
  </Layout>
);

export default PowerOfAttorney;
