import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import FranchiseImage from '../../assets/ContractNegotiation.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const Franchising = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Franchising:</Title>
    <div className="matter-container">
    <Row gutter={[16, 16]}>
    <Col xs={24} md={8}>
          <img src={FranchiseImage} alt="Franchising" style={{ width: '100%', borderRadius: '8px' }} />
        </Col>
        <Col xs={24} md={16}>
          <Card>
            <Title level={4}>Thinking Franchise? Don't Go It Alone. Turn Your Dream into Reality.</Title>
            <Paragraph>
              Franchising can be a powerful growth engine for your business, but the legalities can get complex. That's where we come in – your trusted small business lawyer specializing in franchising.
            </Paragraph>
            
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your vision,</span> we'll handle the legalese. With our expertise, you can turn your franchise dream into a thriving reality.
            </Paragraph>
          </Card>
        </Col>
      </Row>
      <Card>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>We'll help you navigate every step of the journey:</span>
            </Paragraph>
            <Row gutter={[16,16]}>
              <Col xs={24} md={6}>
                <Card title="Build a Solid Foundation" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll guide you in establishing a robust franchise system that sets you up for success.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Expand Your Reach" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Whether you're selling or buying franchises, we'll ensure a smooth and secure transaction.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="FDD Expertise" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll craft a clear and compliant Franchise Disclosure Document (FDD) to attract the right franchisees.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Favorable Franchise Agreements" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll negotiate strong terms that protect your interests and benefit both you and your franchisees.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Legally Sound Operations" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Our expertise ensures your franchise agreements meet all legal requirements.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Compliance Made Easy" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll handle the registration process and guide you through ongoing legal compliance.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Empower Your Franchisees" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll help develop compliant training programs to equip your franchisees for success.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Operations Manual Mastery" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Our guidance ensures your operations manuals are clear and compliant.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Expert Franchise Advice" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>You'll have access to invaluable legal counsel throughout your franchising journey.</Paragraph>
                </Card>
              </Col>
            </Row>
            </Card>
    </div>
  </Layout>
);

export default Franchising;
