import React from 'react';
import { Layout, Row, Col, Card, Typography, Carousel } from 'antd';
import { Link } from 'react-router-dom';
import Banner from '../components/Common/Banner';
import BannerImage from '../assets/homebanner.jpg'; // Replace with your banner image path
import utkarsh from '../assets/utkarsh.jpg';
import '../styles/HomeStyle.css';
import '../styles/CommonStyle.css';
import { HomeOutlined, TeamOutlined, GroupOutlined, InsertRowLeftOutlined, ProfileOutlined, ReadOutlined, TransactionOutlined, RobotOutlined, TrademarkCircleOutlined, UnlockOutlined, RetweetOutlined, DatabaseOutlined, DeploymentUnitOutlined, SwapOutlined, OneToOneOutlined, MehOutlined, SafetyOutlined, FireOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { Content } = Layout;
const { Title, Paragraph } = Typography;

const handleClick = () => {
  // Handle click action here
  console.log('Card clicked!');
};

const Home = () => (
  <Layout>
    {/* Banner Section */}
    <Banner
      backgroundImage={BannerImage}
      title="UT Law"
      subTitle="Cost-Effective Solutions for Individual & Small Business Success!"
      secondSubtitle="Serving the Greater Toronto Area and the rest of Ontario"
    />
    <Content className='mainpage-content'>
      {/* Shortcut button */}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <Link to="/BusinessLaw" className="clickable-card">
            <Card hoverable>
              <InsertRowLeftOutlined className="clickable-card-icon" />
              <Text strong className="clickable-card-text">Business Lawyer</Text>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Link to="/real-estate" className="clickable-card">
            <Card hoverable>
              <HomeOutlined className="clickable-card-icon" />
              <Text strong className="clickable-card-text">Real Estate Lawyer</Text>
            </Card>
          </Link>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <div onClick={handleClick} className="clickable-card">
            <Card hoverable>
              <FireOutlined className="clickable-card-icon" />
              <Text strong className="clickable-card-text">LITIGATION</Text>
            </Card>
          </div>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <div onClick={handleClick} className="clickable-card">
            <Card hoverable>
              <ProfileOutlined className="clickable-card-icon" />
              <Text strong className="clickable-card-text">WILLS & ESTATE</Text>
            </Card>
          </div>
        </Col>
      </Row>
    </Content>
    <Content style={{ padding: '50px' }}>
      {/* Small Bio Section */}
      <Row gutter={[16, 16]} style={{ marginBottom: '50px' }}>
        <Col xs={24} sm={12} md={8}>
          <img src={utkarsh} alt="Utkarsh Tewari" style={{ width: '100%', borderRadius: '8px' }} />
        </Col>
        <Col xs={24} sm={12} md={16}>
          <Title level={2}>Utkarsh Tewari</Title>
          <div className='semitext-background'>
            <Paragraph><span className='semitext'>Your Versatile Legal Partner with a Proven Track Record</span>
          </Paragraph>
          </div>
          <Paragraph>
            <div>
              <Paragraph>
                For nearly two decades, Utkarsh has built a reputation for excellence across a broad range of legal areas. From complex litigation to intricate business deals, he offers clients:
              </Paragraph>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <Card title={<>Deep Expertise</>} className="matter-container-itemlist-card custom-card-header">
                    <Paragraph>Civil Litigation, Corporate Law, Real Estate, Intellectual Property, Family Law, and more.</Paragraph>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Card title={<>Strategic Solutions</>} className="matter-container-itemlist-card custom-card-header">
                    <Paragraph>Combining sharp legal acumen with analytical thinking to craft winning strategies.</Paragraph>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Card title={<>Proven Results</>} className="matter-container-itemlist-card custom-card-header">
                    <Paragraph>A history of achieving positive outcomes for clients in diverse legal matters.</Paragraph>
                  </Card>
                </Col>
              </Row>
            </div>
          </Paragraph>
        </Col>
      </Row>
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={24}>
            <Paragraph>
              <span className='content-sub-minititle-withoutbg'>More Than Just a Lawyer:</span>
              <span>Utkarsh doesn't just handle cases – he builds trusted partnerships.</span>
            </Paragraph>
            <Paragraph className='content-sub-minititle-withoutbg'>He'll be your:</Paragraph>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Card title={<>Trusted Advisor</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Guiding you through complex legal challenges with clear communication.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Card title={<>Skilled Advocate</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Fiercely representing your interests in court, negotiations, or the boardroom.</Paragraph>
            </Card>
          </Col>
          <div className='semitext-background'>
            <Paragraph><span className='semitext'>Utkarsh's commitment to excellence ensures your best interests are always protected.</span></Paragraph>
          </div>
        </Row>
      </div>

      {/* Practice Areas Section */}
      <Title level={2}>Practice Areas</Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={24}>
          <Card title="Business Law" style={{ marginBottom: '20px' }}>
            <p>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Running a business can be legally tricky, but we can help!</span> Our legal experts specialize in business law and can create custom solutions for companies of all sizes, from startups to established players. We offer a wide range of services to fit your specific needs, including crafting and reviewing contracts, helping you incorporate your business, handling business sales and acquisitions, navigating franchise agreements, and securing financing. We'll be your legal guide, protecting your interests and helping your business thrive.
            </p>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} style={{ marginBottom: '20px' }}>
                <Link to="/businesslaw#partnership" className="clickable-card">
                  <Card hoverable>
                    <GroupOutlined className="clickable-card-icon" />
                    <Text strong className="clickable-card-text">Partnership and Shareholders’ Agreements</Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Link to="/businesslaw#licensing" className="clickable-card">
                  <Card hoverable>
                    <ProfileOutlined className="clickable-card-icon" />
                    <Text strong className="clickable-card-text">Licensing and Distribution Agreements</Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Link to="/businesslaw#commercial-lease" className="clickable-card">
                  <Card hoverable>
                    <InsertRowLeftOutlined className="clickable-card-icon" />
                    <Text strong className="clickable-card-text">Commercial Lease drafting / review</Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={12} md={8} style={{ marginBottom: '20px' }}>
                <Link to="/businesslaw#contract-negotiation" className="clickable-card">
                  <Card hoverable>
                    <ReadOutlined className="clickable-card-icon" />
                    <Text strong className="clickable-card-text">Contract Negotiation and Drafting</Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Link to="/businesslaw#business-purchase" className="clickable-card">
                  <Card hoverable>
                    <InsertRowLeftOutlined className="clickable-card-icon" />
                    <Text strong className="clickable-card-text">Business Purchase & Sale</Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Link to="/businesslaw#non-disclosure" className="clickable-card">
                  <Card hoverable>
                    <SafetyOutlined className="clickable-card-icon" />
                    <Text strong className="clickable-card-text">Non-Disclosure Agreements</Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={12} md={8} style={{ marginBottom: '20px' }}>
                <Link to="/businesslaw#franchise" className="clickable-card">
                  <Card hoverable>
                    <TrademarkCircleOutlined className="clickable-card-icon" />
                    <Text strong className="clickable-card-text">Franchise Agreements</Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Link to="/businesslaw#financing" className="clickable-card">
                  <Card hoverable>
                    <DatabaseOutlined className="clickable-card-icon" />
                    <Text strong className="clickable-card-text">Financing</Text>
                  </Card>
                </Link>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Link to="/businesslaw#employment" className="clickable-card">
                  <Card hoverable>
                    <TeamOutlined className="clickable-card-icon" />
                    <Text strong className="clickable-card-text">Employment</Text>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* Testimonials Section */}
      <Title level={2} style={{ marginTop: '50px' }} className='content-sub-minititle-withoutbg-different-textcolor'>Testimonials</Title>
      <Carousel className="testimonials-carousel" dots={false} draggable>
        <div>
          <Card>
            <Title level={4}>Client Testimonial</Title>
            <Paragraph>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, justo vel aliquet tempus, metus nisl facilisis dolor, nec elementum orci lacus eu est."
            </Paragraph>
          </Card>
        </div>
        <div>
          <Card>
            <Title level={4}>Client Testimonial</Title>
            <Paragraph>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, justo vel aliquet tempus, metus nisl facilisis dolor, nec elementum orci lacus eu est."
            </Paragraph>
          </Card>
        </div>
        <div>
          <Card>
            <Title level={4}>Client Testimonial</Title>
            <Paragraph>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, justo vel aliquet tempus, metus nisl facilisis dolor, nec elementum orci lacus eu est."
            </Paragraph>
          </Card>
        </div>
        <div>
          <Card>
            <Title level={4}>Client Testimonial</Title>
            <Paragraph>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, justo vel aliquet tempus, metus nisl facilisis dolor, nec elementum orci lacus eu est."
            </Paragraph>
          </Card>
        </div>
      </Carousel>
    </Content>
  </Layout>
);

export default Home;
