import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import EmploymentImage from '../../assets/ContractNegotiation.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const EmploymentContracts = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Employment Contracts:</Title>
    <div className="matter-container">
    <Row gutter={[16, 16]}>
    <Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
          <Card>
            <Title level={4}>Empowering Businesses & Protecting Workers: Expert Employment Law</Title>
            <Paragraph>
              Building a strong employer-employee relationship starts with solid legal ground. Whether you're an employer or employee, we'll ensure your rights are protected with clear and compliant employment contracts.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your business goals,</span> we'll handle the legalese. With our experience in employment law, you can foster a positive work environment and build strong working relationships.
            </Paragraph>
            
            
            
          </Card>
        </Col>
        <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }}>
          <img src={EmploymentImage} alt="Employment Contracts" style={{ width: '100%', borderRadius: '8px' }} />
        </Col>
      </Row>
      <Card>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Our expertise keeps everyone on the same page:</span>
            </Paragraph>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={6}>
                <Card title="Ironclad Contracts" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll draft or review your employment contract, making sure it's fair, enforceable, and protects your interests.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Negotiation Ninja" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Need help negotiating terms? We'll fight for what matters most to you.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Compliance Champion" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Relax, we'll ensure your contracts comply with all employment laws and regulations.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Dispute Resolution Specialist" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Facing an employment issue? We'll help you navigate disputes and find solutions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Beyond the Basics" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Non-compete agreements, confidentiality clauses – We'll handle it all.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Your Legal Ally" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Know your rights and responsibilities as an employer or employee. We'll provide clear guidance.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Smooth Transitions" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Terminations and severance? We'll guide you through the legal complexities.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Merger & Acquisition Maneuvers" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll handle employment contract due diligence during mergers and acquisitions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Empowered Employees, Protected Businesses" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Develop clear employee handbooks and policies with my guidance.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Stay Informed" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Get up-to-speed with training and workshops on employment law compliance.</Paragraph>
                </Card>
              </Col>
            </Row>
            </Card>
    </div>
  </Layout>
);

export default EmploymentContracts;
