import React from 'react';
import { Modal, Form, Input, Button, message } from 'antd';

const ConsultationForm = ({ visible, onCancel }) => {
  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // Handle form submission logic here (e.g., API call, etc.)
        console.log('Received values:', values);
        message.success('Congratulations! Your form has been submitted successfully.');
        form.resetFields();
        onCancel(); // Close the modal after successful submission
      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
      });
  };

  return (
    <Modal
      title="Consultation Form"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleFormSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="consultationForm">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter your name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter a valid email' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[{ required: true, message: 'Please enter your phone number' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ConsultationForm;
