import React from 'react';
import { Layout, Typography, Divider } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const TermsAndConditions = () => (
  <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
    <Content style={{ padding: '50px 100px', backgroundColor: '#fff', margin: '50px auto', maxWidth: '1000px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Title level={2} style={{ textAlign: 'center' }}>Terms & Conditions</Title>
      <Divider />
      <Paragraph>
        All use of this website is subject to the following Terms and Conditions. If you do not agree with these Terms and Conditions, please do not access or use this website. These terms may be changed by Utkarsh Tewari at any time without notice. Your use of the website constitutes your agreement to be bound by these terms.
      </Paragraph>
      <Title level={3}>Disclaimers</Title>
      <Paragraph>
        The materials provided on this site are for information purposes only. These materials constitute general information relating to several areas of law. They do NOT constitute legal advice or other professional advice and you may not rely on the contents of this website as such.
      </Paragraph>
      <Paragraph>
        The contents of the website do not necessarily represent the opinions of Utkarsh Tewari or his clients. If you require legal advice, you should retain competent legal counsel to advise you. If you would like to retain Utkarsh Tewari, please contact him directly, he will be pleased to discuss whether he can assist you. A solicitor-client relationship will arise between you and Utkarsh Tewari only if he specifically agrees to act for you. Until he specifically agrees to act for you on a matter, you should not provide him with any confidential information or material.
      </Paragraph>
      <Title level={3}>Confidentiality</Title>
      <Paragraph>
        Utkarsh Tewari does not guarantee the confidentiality of any communications sent by e-mail or through his website, or left in voicemail messages. Unsolicited information and material may not be treated as confidential and will not be protected by any solicitor-client privilege. Accessing or using this website does not create a solicitor-client relationship. Although the use of the website may facilitate access to or communications with Utkarsh Tewari by e-mail or voicemail, receipt of any such communications or transmissions by him does not create a solicitor-client relationship, unless he agrees to represent you.
      </Paragraph>
      <Title level={3}>Liability</Title>
      <Paragraph>
        Utkarsh Tewari does not carry liability for any damage arising from the misuse of any information provided on this website. The information provided on the website is not legal advice and should not be relied upon as such. Doing so without seeking the advice of legal counsel constitutes a misuse of the information.
      </Paragraph>
      <Paragraph>
        Although Utkarsh Tewari has made reasonable efforts to ensure that the materials contained on this site are accurate, he does not warrant or guarantee: the accuracy, currency or completeness of the materials; that the site will be available without interruption, error or omission; that defects will be corrected; or that the website and the server(s) that make it available are free from viruses or harmful components. The website and the materials provided on the website are provided “as is” and “as available” without representations, warranties or conditions of any kind, either expressed or implied.
      </Paragraph>
      <Title level={3}>Links to Third Party Sites</Title>
      <Paragraph>
        This website has been designed to be a resource for information on matters that might be of interest to current or potential clients. As a result, there are links throughout the website to third party sites. These links are provided for convenience only, and do not mean that Utkarsh Tewari endorses or recommends the information contained in linked websites, or guarantees its accuracy, timeliness or fitness for a particular purpose. Utkarsh Tewari takes no responsibility for the content or practices of third party sites.
      </Paragraph>
    </Content>
  </Layout>
);

export default TermsAndConditions;
