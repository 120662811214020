import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import ConfidentialityImage from '../../assets/ContractNegotiation.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const Confidentiality = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Confidentiality Agreements:</Title>
    <div className="matter-container">
    <Row gutter={[16, 16]}>
        <Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
          <Card>
            <Title level={4}>Lock Down Your Secrets: Powerful Protection with Confidentiality Agreements</Title>
            <Paragraph>
              Don't let sensitive information leak and hurt your business. We'll craft ironclad confidentiality agreements to safeguard your trade secrets and ensure successful partnerships.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your deals,</span> we'll handle the legalese. With our experience in confidentiality law, you can build strong business relationships and protect your confidential information.
            </Paragraph>
          </Card>
        </Col>
        <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }}>
          <img src={ConfidentialityImage} alt="Confidentiality Agreements" style={{  width: '100%', borderRadius: '8px' }} />
          </Col>
      </Row>
      <Card>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>My expertise keeps your business safe:</span>
            </Paragraph>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <Card title="Confidentiality Craftsman" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll draft or negotiate watertight confidentiality agreements tailored to your specific needs.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title="Breach Blocker" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Facing a confidentiality breach? We'll fight to enforce the agreement and protect your interests.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title="Dispute Defender" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Confidentiality disagreement? We'll help you navigate disputes and find solutions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title="Due Diligence Detective" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Uncover potential confidentiality risks before you enter an agreement.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title="Ongoing Support" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Questions about confidentiality? We are here to provide continuous legal guidance.</Paragraph>
                </Card>
              </Col>
            </Row>
            </Card>
    </div>
  </Layout>
);

export default Confidentiality;
