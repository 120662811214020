import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { NumberOutlined, FileTextOutlined, SearchOutlined, DollarOutlined, InsuranceOutlined, IssuesCloseOutlined, FileProtectOutlined, TransactionOutlined, ProfileOutlined,  EnvironmentOutlined } from '@ant-design/icons';
import IntellectualPropertyImage from '../../assets/litigation-upscaled.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const IntellectualPropertyLitigation = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Intellectual Property Litigation:</Title>
    <div className="matter-container">
    <Row gutter={[16,16]}>
    <Col xs={24} sm={12} md={8}>
          <img src={IntellectualPropertyImage} alt="Intellectual Property" style={{  width: '100%' }} />
        </Col>
        <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Your Ideas. Your Protection. Fight for Your Intellectual Property.</Title>
            <Paragraph>
              In today's digital world, your creative work is your power. We'll be your fierce advocate, protecting your trademarks and copyrights from infringement.
            </Paragraph>
            <Paragraph>
              My expertise empowers you to:
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Stop Infringement:</span> Facing a copycat? We'll fight to defend your intellectual property rights.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Enforce Your Rights:</span> Don't let others profit from your work. We'll help you enforce your ownership.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Strategic Advocacy:</span> Creative solutions for complex legal challenges.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Favorable Outcomes:</span> We'll work tirelessly to achieve the best results for your intellectual property.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on creating,</span>
               we'll handle the legal battles. With our experience in intellectual property litigation, you can protect your ideas and unlock their full potential.
            </Paragraph>
            </Card>
            </Col>
            </Row>
            <Row gutter={[16,16]}>
            <Col xs={24} sm={12} md={24}>
              <Card>
           
            <Row gutter={16}>
            <Col xs={24} sm={12} md={6}>
                <Card title={<><NumberOutlined /> 1. Expert Representation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Expert representation in intellectual property litigation matters, including trademark, and copyright disputes.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileTextOutlined /> 2. Defense Against Claims</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Defense against infringement claims and assertion of intellectual property rights.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><SearchOutlined /> 3. Enforcement Actions</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Enforcement of intellectual property rights through litigation and enforcement actions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><DollarOutlined /> 4. Protection</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Protection of trademarks, trade secrets, and proprietary information.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><InsuranceOutlined /> 5. Pursuit of Damages</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Pursuit of damages and injunctive relief for intellectual property infringement.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><IssuesCloseOutlined /> 6. Domain Name Disputes</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Handling of domain name disputes and online copyright infringement matters.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileProtectOutlined /> 7. Administrative Proceedings</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Representation in administrative proceedings before the CIPO and other regulatory agencies.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><TransactionOutlined /> 8. Strategic Negotiation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Strategic negotiation and settlement of intellectual property disputes.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><EnvironmentOutlined /> 9. Trial Preparation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Preparation for trial and aggressive courtroom advocacy when litigation is necessary.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><ProfileOutlined /> 10. Comprehensive Understanding</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Comprehensive understanding of intellectual property law and industry-specific considerations.</Paragraph>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
        
      </Row>
    </div>
  </Layout>
);

export default IntellectualPropertyLitigation;
