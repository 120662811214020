import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { NumberOutlined, FileTextOutlined, SearchOutlined, DollarOutlined, 
  InsuranceOutlined, IssuesCloseOutlined, FileProtectOutlined, TransactionOutlined, ProfileOutlined, EnvironmentOutlined } from '@ant-design/icons';
import CivilLitigationImage from '../../assets/litigation-upscaled.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const CivilLitigation = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Civil Litigation:</Title>
    <div className="matter-container">
      <Row gutter={[16,16]}>
      <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Facing a Dispute? Get Strong Advocacy & Strategic Solutions.</Title>
            <Paragraph>
              Civil litigation can be complex. We'll be your fierce advocate, guiding you through every step, from case assessment to courtroom victory.
            </Paragraph>
            <Paragraph className='content-minititle-withbg'>
              My expertise empowers you:
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Clear Case Strategy:</span> We'll analyze your situation and develop a winning plan.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Expert Representation:</span> You'll have a skilled negotiator and litigator by your side.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'> Trial Ready:</span> No matter what, we'll prepare you for a successful outcome.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'> Diligent & Effective:</span> We'll fight tirelessly to protect your rights and interests.
            </Paragraph>
            <Paragraph >
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your goals,</span> we'll handle the legal battle. With our experience in civil litigation, you can navigate any dispute with confidence.
            </Paragraph>
            </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <img src={CivilLitigationImage} alt="Civil Litigation" style={{  width: '100%' }} />
        </Col>
      </Row>
      <Card>

           
            <Row gutter={[16,16]}>
            <Col xs={24} sm={12} md={6}>
                <Card title={<><NumberOutlined /> 1. Case Assessment</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Case assessment and strategic legal advice.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileTextOutlined /> 2. Legal Pleadings</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Drafting and filing legal pleadings and motions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><SearchOutlined /> 3. Conducting Discovery</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Conducting discovery, including depositions and document requests.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><DollarOutlined /> 4. Negotiating Settlements</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Negotiating settlements and alternative dispute resolution methods.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><InsuranceOutlined /> 5. Trial Preparation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Preparing for trial and courtroom advocacy.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><IssuesCloseOutlined /> 6. Handling Appeals</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Handling appeals and post-judgment matters.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileProtectOutlined /> 7. Representation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Representation in various civil litigation matters, including contract disputes, tort claims, and business disputes.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><TransactionOutlined /> 8. Tailored Strategies</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Tailored legal strategies to meet the unique needs and objectives of each client.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><EnvironmentOutlined /> 9. Effective Communication</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Effective communication and regular updates throughout the litigation process.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><ProfileOutlined /> 10. Best Outcomes</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Commitment to achieving the best possible outcome for our clients.</Paragraph>
                </Card>
              </Col>
            </Row>
          </Card>
        
    </div>
  </Layout>
);

export default CivilLitigation;
