// src/pages/AboutUs.js
import React from 'react';
import { Layout } from 'antd';
import '../styles/CommonStyle.css';

const { Content } = Layout;

const AboutUs = () => (
  <Layout>
    <Content className="content">
      <div className="content-container">
        About Us
      </div>
    </Content>
  </Layout>
);

export default AboutUs;
