import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import TrademarkImage from '../../assets/treadmark.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const Trademark = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Trademark and Copyright Law:</Title>
    <div className="matter-container">
    <Row gutter={[16, 16]}>
    <Col xs={24} md={8}>
          <img src={TrademarkImage} alt="Trademark and Copyright Law" style={{ width: '100%', borderRadius: '8px' }} />
          </Col>
        <Col xs={24} md={16}>
          <Card>
            <Title level={4}>Shield Your Ideas & Brand: Powerful Intellectual Property Protection</Title>
            <Paragraph>
              In today's digital world, your ideas and brand are your most valuable assets. We'll help you safeguard them with a rock-solid intellectual property (IP) strategy.
            </Paragraph>
            <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on innovation,</span> we'll handle the legalese. With our experience, you can protect your intellectual property and unlock its full potential.
            </Paragraph>
            
          </Card>
        </Col>
      </Row>
      <Card>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>My expertise empowers you to:</span>
            </Paragraph>
            <Row gutter={[16,16]}>
              <Col xs={24} md={6}>
                <Card title="Trademark Titan" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>From registration to enforcement, we'll protect your brand and ensure it stands out.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Copyright Crusader" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Copyright infringement? We'll fight for your rights and protect your creative works.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="IP Portfolio Powerhouse" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Manage your intellectual property effectively with my strategic guidance.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Clearance with Confidence" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Before investing, we'll conduct thorough trademark searches to avoid future roadblocks.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Licensing & Assignment Expertise" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Whether you're licensing your work or assigning rights, we'll craft ironclad agreements.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Domain Name Defender" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Facing a domain name dispute? We'll help you reclaim your rightful online presence.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="IP Audit Advantage" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Uncover potential IP risks and opportunities with a comprehensive IP audit.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Educated Decisions" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Benefit from my IP counseling and education to make informed decisions about your assets.</Paragraph>
                </Card>
              </Col>
            </Row>
            </Card>
    </div>
  </Layout>
);

export default Trademark;
