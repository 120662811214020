import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';
import { Drawer, Menu, Collapse,Button } from 'antd';
import { Link } from 'react-router-dom';
import Logos from './Logo';
import ConsultationForm from '../Common/ConsultationForm';

const { Item, SubMenu } = Menu;
const { Panel } = Collapse;

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: green;
  padding: 0 40px;
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const Logo = styled.div`
  font-size: 24px;
  color: white;
  font-weight: bold;
`;

const CenterMenu = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const DesktopMenu = styled(Menu)`
  display: flex;
  align-items: center;
  background: none;
  border-bottom: none;
  color: white;

  .ant-menu-item, .ant-menu-submenu-title {
    margin: 0 10px;
    font-weight: bold;
    color: white;
  }

  .ant-menu-item:hover, .ant-menu-submenu-title:hover {
    color: lightgray;
  }

  .ant-menu-submenu {
    margin-right: 10px;
  }
`;

const MobileMenuIcon = styled(MenuOutlined)`
  font-size: 24px;
  color: white;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileCollapse = styled(Collapse)`
  .ant-collapse-item {
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-collapse-header {
    font-weight: bold;
  }
`;

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <HeaderWrapper>
      <Logos />
      <CenterMenu>
        <DesktopMenu mode="horizontal">
          <SubMenu key="business-law" title="BUSINESS LAW">
            <Item key="incorporations"><Link to="/businesslaw#incorporations">Incorporations</Link></Item>
            <Item key="partnership-agreements"><Link to="/businesslaw#partnership">Partnership and Shareholders’ Agreements</Link></Item>
            <Item key="business-purchase-sale"><Link to="/businesslaw#business-purchase">Business Purchase & Sale</Link></Item>
            <Item key="commercial-lease"><Link to="/businesslaw#commercial-lease">Commercial Lease Drafting / Review</Link></Item>
            <Item key="licensing-distribution"><Link to="/businesslaw#licensing">Licensing and Distribution Agreements</Link></Item>
            <Item key="contract-negotiation"><Link to="/businesslaw#contract-negotiation">Contract Negotiation and Drafting</Link></Item>
            <Item key="franchising"><Link to="/businesslaw#franchising">Franchising</Link></Item>
            <Item key="employment-contracts"><Link to="/businesslaw#employment-contracts">Employment Contracts</Link></Item>
            <Item key="trademark-copyright"><Link to="/businesslaw#employment-contracts">Employment Contracts</Link></Item>
            <Item key="confidentiality-agreements"><Link to="/businesslaw#confidentiality">Confidentiality Agreements</Link></Item>
            <Item key="service-supply"><Link to="/businesslaw#service-supply">Service and Supply Agreements</Link></Item>
          </SubMenu>
          <SubMenu key="real-estate" title="REAL ESTATE">
            <Item key="residential-real-estate"><Link to="/real-estate#residential-real-estate">Residential Real Estate</Link></Item>
            <Item key="commercial-real-estate"><Link to="/real-estate#commercial-real-estate">Commercial Real Estate</Link></Item>
          </SubMenu>
          <SubMenu key="litigation" title="LITIGATION">
            <Item key="civil-litigation"><Link to="/litigation#civil-litigation">Civil Litigation</Link></Item>
            <Item key="adr"><Link to="/litigation#alternate-dispute-resolution">Alternate Dispute Resolution</Link></Item>
            <Item key="business-litigation"><Link to="/litigation#business-litigation">Business Litigation</Link></Item>
            <Item key="ip-litigation"><Link to="/litigation#intellectual-property-litigation">Intellectual Property Litigation</Link></Item>
            <Item key="landlord-tenant-disputes"><Link to="/litigation#landlord-tenant-disputes">Residential Landlord and Tenant Disputes</Link></Item>
            <Item key="banking-litigation"><Link to="/litigation#banking-litigation">Banking Litigation</Link></Item>
            <Item key="estate-litigation"><Link to="/litigation#estate-litigation">Estate Litigation</Link></Item>
          </SubMenu>
          <SubMenu key="wills-estate" title="WILLS & ESTATE">
            <Item key="wills"><Link to="/willsestate#wills">Wills</Link></Item>
            <Item key="power-of-attorney"><Link to="/willsestate#power-of-attorney">Power of Attorney</Link></Item>
            <Item key="probate"><Link to="/willsestate#probate">Probate</Link></Item>
          </SubMenu>
          <SubMenu key="notary" title="NOTARY">
            <Item key="mobile-notary"><Link to="/notary/mobile">Mobile Notary</Link></Item>
          </SubMenu>
          <SubMenu key="about-us" title="ABOUT US">
            <Item key="team"><Link to="/about-us/blog">Blog</Link></Item>
            <Item key="contact-us"><Link to="/about-us/contact">Contact Us</Link></Item>
          </SubMenu>
        </DesktopMenu>
        <Button type="primary" className='banner-button desktop-only' onClick={showModal}>Get Free Consultant</Button>
      </CenterMenu>
      <MobileMenuIcon onClick={showDrawer} />
      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Button type="primary" className='banner-button desktop-only' onClick={showModal}>Get Free Consultant</Button>
        <MobileCollapse accordion>
          <Panel header="BUSINESS LAW" key="1">
            <Menu mode="vertical">
            <Item key="incorporations"><Link to="/businesslaw#incorporations">Incorporations</Link></Item>
            <Item key="partnership-agreements"><Link to="/businesslaw#partnership">Partnership and Shareholders’ Agreements</Link></Item>
            <Item key="business-purchase-sale"><Link to="/businesslaw#business-purchase">Business Purchase & Sale</Link></Item>
            <Item key="commercial-lease"><Link to="/businesslaw#commercial-lease">Commercial Lease Drafting / Review</Link></Item>
            <Item key="licensing-distribution"><Link to="/businesslaw#licensing">Licensing and Distribution Agreements</Link></Item>
            <Item key="contract-negotiation"><Link to="/businesslaw#contract-negotiation">Contract Negotiation and Drafting</Link></Item>
            <Item key="franchising"><Link to="/businesslaw#franchising">Franchising</Link></Item>
            <Item key="employment-contracts"><Link to="/businesslaw#employment-contracts">Employment Contracts</Link></Item>
            <Item key="trademark-copyright"><Link to="/businesslaw#employment-contracts">Employment Contracts</Link></Item>
            <Item key="confidentiality-agreements"><Link to="/businesslaw#confidentiality">Confidentiality Agreements</Link></Item>
            <Item key="service-supply"><Link to="/businesslaw#service-supply">Service and Supply Agreements</Link></Item>
            </Menu>
          </Panel>
          <Panel header="REAL ESTATE" key="2">
            <Menu mode="vertical">
            <Item key="residential-real-estate"><Link to="/real-estate#residential-real-estate">Residential Real Estate</Link></Item>
            <Item key="commercial-real-estate"><Link to="/real-estate#commercial-real-estate">Commercial Real Estate</Link></Item>
            </Menu>
          </Panel>
          <Panel header="LITIGATION" key="3">
            <Menu mode="vertical">
            <Item key="civil-litigation"><Link to="/litigation#civil-litigation">Civil Litigation</Link></Item>
            <Item key="adr"><Link to="/litigation#alternate-dispute-resolution">Alternate Dispute Resolution</Link></Item>
            <Item key="business-litigation"><Link to="/litigation#business-litigation">Business Litigation</Link></Item>
            <Item key="ip-litigation"><Link to="/litigation#intellectual-property-litigation">Intellectual Property Litigation</Link></Item>
            <Item key="landlord-tenant-disputes"><Link to="/litigation#landlord-tenant-disputes">Residential Landlord and Tenant Disputes</Link></Item>
            <Item key="banking-litigation"><Link to="/litigation#banking-litigation">Banking Litigation</Link></Item>
            <Item key="estate-litigation"><Link to="/litigation#estate-litigation">Estate Litigation</Link></Item>
            </Menu>
          </Panel>
          <Panel header="WILLS & ESTATE" key="4">
            <Menu mode="vertical">
            <Item key="wills"><Link to="/willsestate#wills">Wills</Link></Item>
            <Item key="power-of-attorney"><Link to="/willsestate#power-of-attorney">Power of Attorney</Link></Item>
            <Item key="probate"><Link to="/willsestate#probate">Probate</Link></Item>
            </Menu>
          </Panel>
          <Panel header="NOTARY" key="5">
            <Menu mode="vertical">
            <Item key="mobile-notary"><Link to="/notary">Mobile Notary</Link></Item>
            </Menu>
          </Panel>
          <Panel header="ABOUT US" key="6">
            <Menu mode="vertical">
            <Item key="team"><Link to="/about-us/blog">Blog</Link></Item>
            <Item key="contact-us"><Link to="/ContactUs">Contact Us</Link></Item>
              
            </Menu>
          </Panel>
          
        </MobileCollapse>
        
      </Drawer>
      <ConsultationForm visible={modalVisible} onCancel={handleCancel} />
    </HeaderWrapper>
  );
};

export default Header;
