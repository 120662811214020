// src/pages/Blog.js
import React from 'react';
import { Layout } from 'antd';
import '../styles/CommonStyle.css';

const { Content } = Layout;

const Blog = () => (
  <Layout>
    <Content className="content">
      <div className="content-container">
        Blog
      </div>
    </Content>
  </Layout>
);

export default Blog;
