import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { SafetyCertificateOutlined, AuditOutlined, SolutionOutlined, CheckOutlined } from '@ant-design/icons';
import NotaryImage from '../../assets/notary.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const Notary = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Notary:</Title>
    <div className="matter-container">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <img src={NotaryImage} alt="Notary" style={{ width: '100%' }} />
        </Col>
        <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Get Your Documents Signed, Sealed, and Delivered: Fast & Easy Notary Services</Title>
            <Paragraph>
              Need a document notarized? Skip the hassle! We provide efficient and professional notary services to ensure your documents are legal and authentic.
            </Paragraph>
            <Paragraph>
              From real estate deals to power of attorney, we can help with:
            </Paragraph>
            <ul>
              <li>Signature Verification: Making sure your signature is legit.</li>
              <li>Document Certification: Adding an official stamp of approval.</li>
              <li>Oath Administration: Swearing to the truth of your statements.</li>
            </ul>
            <Paragraph>
              Focus on what matters, we'll handle the formalities. With our notary services, your documents will be ready to go quickly and conveniently.
            </Paragraph>
          </Card>
        </Col>
      </Row>
      <Card>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><SafetyCertificateOutlined /> Authentication</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Authentication of signatures on legal documents.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><AuditOutlined /> Certification</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Certification of document copies.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><SolutionOutlined /> Oath Administration</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Administering oaths and affirmations.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><CheckOutlined /> Witnessing Signatures</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Witnessing and attesting signatures on legal documents.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><SafetyCertificateOutlined /> Real Estate</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Notarization of real estate documents, including deeds and mortgages.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><AuditOutlined /> Legal Instruments</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Notarization of powers of attorney, affidavits, and other legal instruments.</Paragraph>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  </Layout>
);

export default Notary;
