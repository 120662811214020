import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { FileTextOutlined, DollarOutlined, UserOutlined, EnvironmentOutlined } from '@ant-design/icons';
import WillsImage from '../../assets/banner.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const Wills = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Wills:</Title>
    <div className="matter-container">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Protect Your Legacy & Loved Ones: Expert Will Drafting & Estate Planning</Title>
            <Paragraph>
              Planning for your future ensures your wishes are clear and your family is cared for. We'll guide you through every step, crafting a will that reflects your intentions and minimizes taxes.
            </Paragraph>
            <Paragraph>
              My expertise empowers you to:
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Clearly Express Your Wishes:</span> Leave no doubt about your desires for your legacy.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Secure Your Loved Ones' Future:</span> Provide peace of mind with a comprehensive estate plan.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Minimize Tax Burdens:</span> We'll help you navigate tax implications and maximize what's left for your heirs.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'> Focus on what matters most,</span> we'll handle the details. With our experience in will drafting and estate planning, you can create a lasting legacy with confidence.
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <img src={WillsImage} alt="Wills" style={{ width: '100%' }} />
        </Col>
      </Row>
      <Card>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><FileTextOutlined /> Expert Guidance</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Expert guidance in drafting and reviewing wills.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><DollarOutlined /> Minimize Taxes</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Guidance on asset distribution to beneficiaries and minimizing tax implications.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><UserOutlined /> Guardianship</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Including provisions for guardianship of minor children, if applicable.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><EnvironmentOutlined /> Healthcare Directives</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Drafting living wills and healthcare directives to outline medical preferences.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><FileTextOutlined /> Review & Update</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Reviewing and updating existing wills to accommodate life changes.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><DollarOutlined /> Estate Planning Strategies</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Providing advice on estate planning strategies to preserve wealth and assets.</Paragraph>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  </Layout>
);

export default Wills;
