import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { TeamOutlined, CarryOutOutlined, SwapOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import PartnershipImage from '../../assets/partnership.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const Partnership = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Partnership and Shareholders' Agreements:</Title>
    <div className="matter-container">
      <Row gutter={[16,16]}>
      <Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
          <Card>
            <Title level={4}>Solid foundations for your business partnership. We craft ironclad agreements.</Title>
            <Paragraph>
              Avoid confusion and future headaches with a customized partnership or shareholder agreement. Whether you're launching a new venture or fine-tuning an existing one, we'll draft a clear and comprehensive agreement tailored to your unique needs.
            </Paragraph>
            <Paragraph>
              <span className='content-minititle-withbg'>Here's what we'll cover for you:</span>
              
            </Paragraph>
            <ul>
              <li><span className='content-sub-minititle-withoutbg'>Ownership & Profit Sharing:</span> We'll define clear ownership structures and fair profit-sharing arrangements.</li>
              <li><span className='content-sub-minititle-withoutbg'>Decision-Making:</span> Establish a smooth decision-making process to avoid future roadblocks.</li>
              <li><span className='content-sub-minititle-withoutbg'>Exit Strategies:</span> Craft clear plans for handling situations like partner departures or business dissolution.</li>
            </ul>
           
          </Card>
        </Col>
        <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }}>
          <img src={PartnershipImage} alt="Partnership" style={{  width: '100%', borderRadius: '8px' }} />
        </Col>
      </Row>
      <Card>
      <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Peace of mind for your business journey. </span>With our expertise, you can be confident your agreement protects everyone involved and fosters long-term success.
            </Paragraph>
            <Row gutter={[16,16]}>
            <Col xs={24} md={8}>
                <Card title={<><TeamOutlined /> Ownership & Profit Sharing</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Define clear ownership structures and fair profit-sharing arrangements.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title={<><CarryOutOutlined /> Decision-Making</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Establish a smooth decision-making process to avoid future roadblocks.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title={<><SwapOutlined /> Exit Strategies</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Craft clear plans for handling partner departures or business dissolution.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title={<><SafetyCertificateOutlined /> Legal Compliance</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Ensure your agreement meets all legal requirements, giving you peace of mind.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title={<><TeamOutlined /> Expert Guidance</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Benefit from our legal expertise to ensure clarity, fairness, and enforceability of your agreement.</Paragraph>
                </Card>
              </Col>
            </Row>
      </Card>
    </div>
  </Layout>
);

export default Partnership;
