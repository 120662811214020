import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { FileTextOutlined, UserOutlined, DollarOutlined, EnvironmentOutlined } from '@ant-design/icons';
import ProbateImage from '../../assets/banner.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const Probate = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Probate:</Title>
    <div className="matter-container">
      <Row gutter={[16, 16]}>
      <Col xs={24} sm={12} md={8}>
          <img src={ProbateImage} alt="Probate" style={{ width: '100%' }} />
        </Col>
        <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Guiding You Through Probate: Less Stress, More Peace of Mind</Title>
            <Paragraph>
              Losing a loved one is hard. Let us handle the complexities of probate so you can focus on healing.
            </Paragraph>
            <Paragraph>
              My expertise eases the burden:
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Clear & Efficient Process:</span> We'll guide you through every step, simplifying probate and minimizing delays.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Trusted Advocate:</span> You'll have a knowledgeable professional by your side, answering your questions and protecting your interests.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Distributing Assets with Care:</span> Ensuring your loved one's wishes are followed accurately.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on honoring your loved one,</span> we'll handle the legalese. With our experience in probate law, you can navigate this difficult time with confidence and clarity.
            </Paragraph>
          </Card>
        </Col>
        
      </Row>
      <Card>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><FileTextOutlined /> Probate Proceedings</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Initiation and management of probate proceedings.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><UserOutlined /> Executor Responsibilities</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Guidance on fulfilling executor responsibilities and duties.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><DollarOutlined /> Asset Valuation</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Identification and valuation of estate assets.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><UserOutlined /> Creditor Claims</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Resolving creditor claims and outstanding debts.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><FileTextOutlined /> Court Documents</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Preparation and filing of required probate court documents.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title={<><EnvironmentOutlined /> Estate Taxes</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Advice on estate tax implications and minimizing tax liabilities.</Paragraph>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  </Layout>
);

export default Probate;
