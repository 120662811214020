// src/pages/Notary.js
import React from 'react';
import { Layout } from 'antd';
import '../styles/CommonStyle.css';
import Banner from '../components/Common/Banner';
import BannerImage from '../assets/homebanner.jpg';
//sub modules
import MobileNotary from './notary/MobileNotary';


const { Content } = Layout;

const Notary = () => (
  <Layout>
    {/* Banner Section */}
    <Banner
        backgroundImage={BannerImage}
        title="Business Law"
        subTitle="Cut through Ontario's business law maze with ease. We'll guide you."
        secondSubtitle="As your trusted business lawyer, we provide expert advice and personalized solutions to navigate any legal hurdle. Don't wait, contact us today to unlock your business potential."
      />
      <Content style={{ paddingLeft: '50px', paddingRight: '50px', paddingBottom: '50px' }}>
        <MobileNotary/>
      </Content>
  </Layout>
);

export default Notary;
