import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import UtkarshImage from '../../assets/commercial.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const CommercialLease = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Commercial Lease drafting / review:</Title>
    <div className="matter-container">
      <Row gutter={[16, 16]}>
        <Col xs={24} md={16}>
          <Card>
            <Title level={4}>Commercial Leases Made Easy: Secure Your Deal with Confidence</Title>
            <Paragraph>
              Don't get bogged down in legal jargon. Whether you're a landlord or tenant, we'll ensure your commercial lease is clear, fair, and protects your interests.
            </Paragraph>
            <Paragraph>Focus on your business, we'll handle the legalities. With our experience, you can enter into a secure and successful commercial lease agreement.</Paragraph>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <img src={UtkarshImage} alt="Utkarsh Tewari" style={{ width: '100%', borderRadius: '8px' }} />
        </Col>
      </Row>
      <Card>
        <Paragraph>
          <span className='content-sub-minititle-withoutbg-different-textcolor'>Our expertise helps you win:</span>
        </Paragraph>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Card title="Ironclad Lease Agreements" className="matter-container-itemlist-card custom-card-header">
              <Paragraph>We'll meticulously draft or review your lease, leaving no loopholes and safeguarding your rights.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title="Negotiation Ninja" className="matter-container-itemlist-card custom-card-header">
              <Paragraph>We'll fight for the best terms, maximizing your advantage in the negotiation process.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title="Crystal Clear Clauses" className="matter-container-itemlist-card custom-card-header">
              <Paragraph>No confusing legalese here. We'll ensure every provision is clear and enforceable.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title="Compliance Guaranteed" className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Relax, we'll ensure the agreement meets all legal and industry standards.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title="Tailored to Your Needs" className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Every business is unique. We'll customize the lease to fit your specific situation.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card title="Ongoing Support" className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Questions don't stop at signing. We are here to guide you throughout the entire lease term.</Paragraph>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  </Layout>
);

export default CommercialLease;
