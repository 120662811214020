// src/pages/RealEstate.js
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import '../styles/CommonStyle.css';
import Banner from '../components/Common/Banner';
import BannerImage from '../assets/homebanner.jpg';
//submodules
import CommercialRealEstate from './realEstate/CommercialRealEstate';
import ResidentialRealEstate from './realEstate/ResidentialRealEstate';

const { Content } = Layout;

const RealEstate = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Layout>
      {/* Banner Section */}
      <Banner
        backgroundImage={BannerImage}
        title="Secure Your Real Estate Deal"
        subTitle="Trusted Lawyer, Proven Results"
        secondSubtitle="Serving the Greater Toronto Area and the rest of Ontario"
      />
      <Content style={{ paddingLeft: '50px', paddingRight: '50px', paddingBottom: '50px' }}>
        <div id="commercial-real-estate"><CommercialRealEstate /></div>
        <div id="residential-real-estate"><ResidentialRealEstate /></div>
      </Content>
    </Layout>
  );
};

export default RealEstate;
