import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { NumberOutlined, FileTextOutlined, SearchOutlined, DollarOutlined, InsuranceOutlined, IssuesCloseOutlined, FileProtectOutlined, TransactionOutlined, EnvironmentOutlined } from '@ant-design/icons';
import BankingLitigationImage from '../../assets/litigation-upscaled.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const BankingLitigation = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Banking Litigation:</Title>
    <div className="matter-container">
      <Row gutter={[16,16]}>
      <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Banking Dispute? We'll Be Your Strong Voice in Financial Court.</Title>
            <Paragraph>
              Navigating banking legal issues can be complex. Whether you're a financial institution facing a lawsuit or an individual in a dispute with a bank, we'll be your fierce advocate.
            </Paragraph>
            <Paragraph>
              My expertise gets results:
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Financial Institutions:</span> Breach of contract claims? We'll fight to protect your interests.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Consumers:</span> Facing unfair bank practices? We'll defend your rights.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Strategic Advocacy & Clear Guidance:</span> We'll navigate the legal complexities and develop a winning strategy for your specific case.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Favorable Outcomes:</span> Our focus is on achieving the best possible results for you.
            </Paragraph>
            <Paragraph >
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your finances, </span>we'll handle the legalese. With our experience in banking litigation, you can approach any financial dispute with confidence and clarity.
            </Paragraph>
            </Card>
        </Col>
        
        <Col xs={24} sm={12} md={8}>
          <img src={BankingLitigationImage} alt="Civil Litigation" style={{  width: '100%' }} />
        </Col>
      </Row>
      <Card>

            
            <Row gutter={[16,16]}>             
          <Col xs={24} sm={12} md={8}>
                <Card title={<><NumberOutlined /> 1. Expert Representation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Expert representation in a wide range of banking litigation matters.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><FileTextOutlined /> 2. Breach of Contract</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Defense against breach of contract claims and enforcement of lending agreements.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><SearchOutlined /> 3. Consumer Litigation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Representation in consumer litigation, including claims of unfair or deceptive practices.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><DollarOutlined /> 4. Debt Collection</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Pursuit of debt collection and recovery actions on behalf of financial institutions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><InsuranceOutlined /> 5. Fraud Defense</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Defense against claims of fraud, misrepresentation, and negligence.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><IssuesCloseOutlined /> 6. Dispute Settlement</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Negotiation and settlement of disputes to avoid costly litigation when possible.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><FileProtectOutlined /> 7. Trial Preparation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Preparation for trial and aggressive courtroom advocacy when litigation is unavoidable.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><TransactionOutlined /> 8. Best Outcomes</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Commitment to achieving the best possible outcome for our banking clients.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Card title={<><EnvironmentOutlined /> 9. Comprehensive Understanding</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Comprehensive understanding of banking law.</Paragraph>
                </Card>
              </Col>
            </Row>
          </Card>
        
    </div>
  </Layout>
);

export default BankingLitigation;
