// src/pages/Litigation.js
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import '../styles/CommonStyle.css';
import BannerImage from '../assets/homebanner.jpg';
import Banner from '../components/Common/Banner';
//submodules
import AlternateDisputeResolution from './litigation/AlternateDisputeResolution';
import BankingLitigation from './litigation/BankingLitigation';
import BusinessLitigation from './litigation/BusinessLitigation';
import CivilLitigation from './litigation/CivilLitigation';
import EstateLitigation from './litigation/EstateLitigation';
import IntellectualPropertyLitigation from './litigation/IntellectualPropertyLitigation';
import LandlordTenantDisputes from './litigation/LandlordTenantDisputes';

const { Content } = Layout;

const Litigation = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Layout>
      {/* Banner Section */}
      <Banner
        backgroundImage={BannerImage}
        title="Business Law"
        subTitle="Cut through Ontario's business law maze with ease. We'll guide you."
        secondSubtitle="As your trusted business lawyer, we provide expert advice and personalized solutions to navigate any legal hurdle. Don't wait, contact us today to unlock your business potential."
      />
      <Content style={{ paddingLeft: '50px', paddingRight: '50px', paddingBottom: '50px' }}>
        <div id="alternate-dispute-resolution"><AlternateDisputeResolution /></div>
        <div id="banking-litigation"><BankingLitigation /></div>
        <div id="business-litigation"><BusinessLitigation /></div>
        <div id="civil-litigation"><CivilLitigation /></div>
        <div id="estate-litigation"><EstateLitigation /></div>
        <div id="intellectual-property-litigation"><IntellectualPropertyLitigation /></div>
        <div id="landlord-tenant-disputes"><LandlordTenantDisputes /></div>
      </Content>
    </Layout>
  );
};

export default Litigation;
