import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import {  PhoneOutlined, UserOutlined } from '@ant-design/icons'; // Import necessary icons
import ConsultationForm from './ConsultationForm';

const Banner = ({ backgroundImage, title, subTitle, secondSubtitle }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <div className="banner" style={{ backgroundImage: `url(${backgroundImage})`, height: '50vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="banner-content">
        <Row gutter={[24, 24]} className="banner-row-area">
          <Col className="banner-col-area" xs={24} sm={12} md={16}>
            <h1 className="banner-content-title">{title}</h1>
            <p className="banner-content-sub-title">{subTitle}</p>
            <p className="banner-content-second-subtitle">{secondSubtitle}</p>
            <div>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12}>
                  <Button type="primary" onClick={showModal} className="banner-button" icon={<UserOutlined />} size="large">Get Free Consultation Now</Button>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Button 
                    type="primary" 
                    className="banner-button no-hover-effect" 
                    icon={<PhoneOutlined />} 
                    size="large"
                    style={{ cursor: 'default' }}
                  >
                    Tel: (416) 756 7500 Extn 2229
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <ConsultationForm visible={modalVisible} onCancel={handleCancel} />
    </div>
  );
};

export default Banner;
