// src/components/Header/Logo.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
const Logo = () => (
  <div className="logo" >
    <Link to="/">
    <img src={logo} alt="Map Placeholder" style={{ width: '9%', height: '9%', }} />
    <span style={{color:'white',paddingLeft:'5px'}}>Utkarsh Tewari</span>
    </Link>
  </div>
);

export default Logo;
