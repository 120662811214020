import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { NumberOutlined, FileTextOutlined, SearchOutlined, DollarOutlined, InsuranceOutlined, IssuesCloseOutlined, FileProtectOutlined, TransactionOutlined, ProfileOutlined, EnvironmentOutlined } from '@ant-design/icons';
import ADRImage from '../../assets/litigation-upscaled.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const AlternateDisputeResolution = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Alternate Dispute Resolution:</Title>
    <div className="matter-container">
    <Row gutter={[16,16]}>
    <Col xs={24} sm={12} md={8}>
          <img src={ADRImage} alt="Alternate Dispute Resolution" style={{width: '100%' }} />
        </Col>
        <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Skip the Courtroom. Resolve Disputes Faster & Smarter.</Title>
            <Paragraph>
              Alternate Dispute Resolution (ADR) offers a powerful alternative to litigation. We'll help you navigate the process to reach a fair and cost-effective solution, saving you time and money.
            </Paragraph>
            <Paragraph>
              <strong>My expertise guides you to:</strong>
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Productive Conversations:</span> We'll facilitate discussions that help both sides be heard.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Creative Solutions:</span> We'll explore options beyond traditional courtroom battles.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Mutually Beneficial Outcomes:</span> Our goal is to find an agreement that works for everyone involved.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Faster Resolutions:</span> Avoid the delays and expense of court.
            </Paragraph>
            <Paragraph>
             <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on moving forward,</span> we'll handle the legalese. With our experience in ADR, you can resolve disputes efficiently and confidently.
            </Paragraph>
            </Card>
            </Col>
            </Row>
            <Row gutter={[16,16]}>
            <Col xs={24} sm={12} md={24}>
              <Card>
                
            
            <Row gutter={[16,16]}>
            <Col xs={24} sm={12} md={6}>
                <Card title={<><NumberOutlined /> 1. Expert Guidance</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Expert guidance and representation in mediation, arbitration, and negotiation processes.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileTextOutlined /> 2. Tailored Strategies</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Tailored strategies to meet the unique needs and goals of each client.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><SearchOutlined /> 3. Productive Discussions</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Facilitation of productive discussions and exploration of creative solutions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><DollarOutlined /> 4. Cost-Effective</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Cost-effective and time-efficient alternatives to traditional litigation.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><InsuranceOutlined /> 5. Client Interests</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Protection of client interests while promoting amicable resolutions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><IssuesCloseOutlined /> 6. ADR Principles</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Comprehensive understanding of ADR principles and procedures.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileProtectOutlined /> 7. Skilled Advocacy</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Skilled advocacy and negotiation to achieve favorable outcomes.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><TransactionOutlined /> 8. Settlement Agreements</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Assistance with drafting and finalizing settlement agreements.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><EnvironmentOutlined /> 9. Continued Support</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Continued support and guidance throughout the ADR process.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><ProfileOutlined /> 10. Satisfactory Resolutions</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Commitment to achieving mutually satisfactory resolutions for all parties involved.</Paragraph>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
       
      </Row>
    </div>
  </Layout>
);

export default AlternateDisputeResolution;
