import React from 'react';
import { Layout} from 'antd';
import Banner from '../components/Common/Bannercontact';
import BannerImage from '../assets/homebanner.jpg';
import '../styles/CommonStyle.css';

const { Content } = Layout;

const ContactUs = () => (
  <Layout>
    {/* Banner Section */}
    <Banner
      backgroundImage={BannerImage}
      title="Contact Us"
      subTitle=""
      secondSubtitle=""
    />
    <Content className="content">
     
    </Content>
  </Layout>
);

export default ContactUs;
