import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { FileTextOutlined, IssuesCloseOutlined, FileProtectOutlined, TransactionOutlined, ProfileOutlined, EnvironmentOutlined } from '@ant-design/icons';
import EstateLitigationImage from '../../assets/litigation-upscaled.jpg'; // Ensure the correct path to your image
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const EstateLitigation = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Estate Litigation:</Title>
    <div className="matter-container">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Family Inheritance Dispute? Get Clarity & Resolve It Efficiently.</Title>
            <Paragraph>
              Losing a loved one is hard. Inheritance disagreements shouldn't add to the stress. We'll be your trusted guide, navigating complex estate disputes with compassion and expertise.
            </Paragraph>
            <Paragraph>My experience protects your interests:</Paragraph>
            <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Will Challenges:</span> Contesting a will? We'll ensure your voice is heard.
            </Paragraph>
            <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Beneficiary Disputes:</span> Facing disagreements about inheritance? We'll help find solutions.
            </Paragraph>
            <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Misconduct Defense:</span> Accused of mismanagement? We'll fight to protect your reputation.
            </Paragraph>
            <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Clear Communication & Effective Advocacy:</span> We'll keep you informed and advocate fiercely for your rights throughout the process.
            </Paragraph>
            <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Favorable Resolutions:</span> Our goal is to achieve an outcome that honors your loved one's wishes and minimizes family conflict.
            </Paragraph>
            <Paragraph>Focus on healing, we'll handle the legalese. With our experience in estate litigation, you can navigate these difficult situations with confidence and clarity.</Paragraph>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <img src={EstateLitigationImage} alt="Estate Litigation" style={{ width: '100%' }} />
        </Col>
      </Row>
      <Card>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><FileTextOutlined /> Representation</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Representation in will contests and challenges to the validity of estate planning documents.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><IssuesCloseOutlined /> Beneficiary Disputes</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Resolving disputes among beneficiaries over asset distribution and inheritance rights.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><FileProtectOutlined /> Misconduct Defense</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Defending estate administrators against claims of breach of fiduciary duty or mismanagement.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><TransactionOutlined /> Financial Elder Abuse</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Pursuit of claims for financial elder abuse or undue influence in estate matters.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><EnvironmentOutlined /> Trust Litigation</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Handling of trust litigation, including disputes over trust administration and interpretation.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><ProfileOutlined /> Probate Court</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Representation in probate court proceedings and estate settlement matters.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><TransactionOutlined /> Dispute Settlement</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Negotiation and settlement of disputes to avoid protracted litigation when possible.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><ProfileOutlined /> Trial Preparation</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Preparation for trial and aggressive courtroom advocacy when litigation is unavoidable.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><EnvironmentOutlined /> Estate Planning</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Assistance with estate planning strategies to prevent future disputes.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card title={<><ProfileOutlined /> Compassionate Support</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Compassionate and understanding support throughout the estate litigation process.</Paragraph>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  </Layout>
);

export default EstateLitigation;
