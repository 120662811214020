import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { NumberOutlined, FileTextOutlined, SearchOutlined, DollarOutlined, InsuranceOutlined, IssuesCloseOutlined, FileProtectOutlined, UserOutlined, TransactionOutlined, InfoCircleOutlined, PropertySafetyOutlined, ProfileOutlined, WifiOutlined } from '@ant-design/icons';
import RealEstateImage from '../../assets/residential.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const ResidentialRealEstate = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Residential Real Estate:</Title>
    <div className="matter-container">
      <Row gutter={[16,16]}>
      <Col xs={24} md={16}>
          <Card>
            <Title level={4}>Stress-Free Real Estate: Trusted Guidance for Your Home Journey</Title>
            <Paragraph>
              Don't let legal hurdles stall your dream home. We'll guide you through every step of the residential real estate process, ensuring a smooth and successful transaction.
            </Paragraph>
            
            
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <img src={RealEstateImage} alt="Residential Real Estate" style={{width: '100%',border:'8px' }} />
        </Col>
      </Row>
      <Card>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>My experience protects your interests:</span>
            </Paragraph>
            <Row gutter={[16,16]}>
            <Col xs={24} md={6}>
                <Card title={<><NumberOutlined /> 1. Assistance</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Assistance with buying or selling residential properties.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><FileTextOutlined /> 2. Contract Negotiation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Contract negotiation and drafting of buying, selling, or leasing residential properties.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><SearchOutlined /> 3. Reviewing</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Reviewing the title documents and conducting due diligence.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><DollarOutlined /> 4. Financing Assistance</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Assistance with financing, mortgages, and refinancing.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><InsuranceOutlined /> 5. Title Insurance</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Title insurance and property transfer tax matters.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><IssuesCloseOutlined /> 6. Dispute Resolution</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Resolving disputes related to residential property ownership or boundaries.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><FileProtectOutlined /> 7. Transaction </>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Resolving disputes related to residential real estate transactions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><UserOutlined /> 8. Landlord-Tenant </>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Guidance on landlord-tenant issues and lease agreements.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><TransactionOutlined /> 9. Property Transfers</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Facilitating property transfers and closings.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><InfoCircleOutlined /> 10. Legal Advice</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Advice on legal rights and obligations for homeowners and tenants.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><PropertySafetyOutlined /> 11. Property Tax</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Providing guidance on property tax assessments and exemptions.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><ProfileOutlined /> 12. Zoning Compliance</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Compliance with local zoning and land use regulations.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title={<><WifiOutlined /> 13. Ongoing Support</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Ongoing legal support for homeowners and landlords.</Paragraph>
                </Card>
              </Col>
            </Row>
            </Card>
    </div>
  </Layout>
);

export default ResidentialRealEstate;
