import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import ServiceSupplyImage from '../../assets/supply.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const ServiceSupply = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Service and Supply Agreements:</Title>
    <div className="matter-container"> 
      <Row gutter={[16, 16]}>
    <Col xs={{ span: 24, order: 1}} md={{ span: 8, order: 2 }}>
          <img src={ServiceSupplyImage} alt="Service and Supply Agreements" style={{width: '100%', borderRadius: '8px' }} />
          </Col>
          <Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
          <Card>
            <Title level={4}>Solid Agreements, Strong Partnerships: Your Service & Supply Legal Expert</Title>
            <Paragraph>
              Clear agreements are the foundation of successful business relationships. We'll craft ironclad service and supply agreements tailored to your needs, ensuring smooth transactions and protected interests.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your business partnerships,</span> we'll handle the legalese. With our experience, you can enter into service and supply agreements with confidence and security.
            </Paragraph>
          </Card>
        </Col>
      </Row>
      <Card>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Our expertise keeps your deals on track:</span>
            </Paragraph>
            <Row gutter={16}>
            <Col xs={24} md={6}>
                <Card title="Deal Architect" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll draft and negotiate watertight agreements that meet your specific business goals.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Compliance Champion" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Relax, we'll ensure your agreements comply with all legal requirements and best practices.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Enforcement Enforcer" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Facing a breach of contract? We'll fight to enforce your rights and hold the other party accountable.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Dispute Decoder" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Contract disagreements? We'll help navigate them and find solutions that benefit you.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Due Diligence Detective" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Uncover potential risks before you sign, protecting your business from surprises.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Risk Management Master" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>We'll advise you on potential risks and liabilities, minimizing your exposure.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={6}>
                <Card title="Ongoing Support" className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Questions throughout the process? We are here to provide continuous legal guidance.</Paragraph>
                </Card>
              </Col>
            </Row>
            </Card>
    </div>
  </Layout>
);

export default ServiceSupply;
