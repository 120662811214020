import React from 'react';
import { Layout,Col,Row,Typography,Card } from 'antd';
import UtkarshImage from '../../assets/ContractNegotiation.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const ContractNegotiation = () => (
  <Layout>
  <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Contract Negotiation and Drafting:</Title>
    <div className="matter-container">
    <Row gutter={[16, 16]}>
    <Col xs={24} md={8}>
        <img src={UtkarshImage} alt="Utkarsh Tewari" style={{ width:'100%', borderRadius: '8px' }} />
      </Col>
      <Col xs={24} md={16}>
          <Card>
            <Title level={4}>Solid Contracts, Secure Future: Expert Negotiation & Drafting for Your Business</Title>
            <Paragraph>
            Don't leave your business exposed with weak contracts. We'll craft ironclad agreements for any deal, big or small, ensuring your interests are protected and you achieve a win-win outcome.
            </Paragraph>
            <Paragraph><span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your business goals,</span>weI'll handle the legalese. With our experience, you can enter into any agreement with confidence and peace of mind.</Paragraph>
          </Card>
          </Col>
      </Row>
      <Card>
              <Paragraph><span className='content-sub-minititle-withoutbg-different-textcolor'>Our expertise empowers you:</span></Paragraph>    
            
              <Row gutter={[16,16]}>
              <Col xs={24} md={8}>
               <Card title="Dealmaker & Defender" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>We'll negotiate the best terms and draft contracts that are clear, concise, and protect your rights.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card  title="Crystal Clear Clauses" className="matter-container-itemlist-card custom-card-header" > 
                <Paragraph>No room for misinterpretations. We'll define terms, conditions, and obligations for all parties involved.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card title="Risk Shield" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>We'll anticipate potential issues and draft provisions that mitigate risks and liabilities.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card title="Compliance Guaranteed" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>Relax, we'll ensure the agreement meets all legal and industry standards.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card title="Win-Win Negotiations" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>We'll fight for favorable terms that benefit everyone involved.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card title="Ongoing Support" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>Questions? We are by your side throughout the entire process, from negotiation to final draft.
                </Paragraph>
               </Card>
                </Col>
                </Row>
              </Card>
    </div>
</Layout>
);

export default ContractNegotiation;
