// src/pages/WillsEstate.js
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import '../styles/CommonStyle.css';
import Banner from '../components/Common/Banner';
import BannerImage from '../assets/homebanner.jpg';
//submodules
import PowerOfAttorney from './willsEstate/PowerOfAttorney';
import Probate from './willsEstate/Probate';
import Wills from './willsEstate/Wills';

const { Content } = Layout;

const WillsEstate = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Layout>
      <Banner
        backgroundImage={BannerImage}
        title="Secure Your Real Estate Deal"
        subTitle="Trusted Lawyer, Proven Results"
        secondSubtitle="Serving the Greater Toronto Area and the rest of Ontario"
      />
      <Content style={{ paddingLeft: '50px', paddingRight: '50px', paddingBottom: '50px' }}>
        <div id="power-of-attorney"><PowerOfAttorney /></div>
        <div id="probate"><Probate /></div>
        <div id="wills"><Wills /></div>
      </Content>
    </Layout>
  );
};

export default WillsEstate;
