import React from 'react';
import { Layout,Col,Row,Typography,Card } from 'antd';
import licImage from '../../assets/lic.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const { Content } = Layout;

const Licensing = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Licensing and Distribution Agreements:</Title>
      <div className="matter-container">
      <Row gutter={[16, 16]}>
      <Col xs={24} md={8}>
          <img src={licImage} alt="Utkarsh Tewari" style={{ height: '100%',width:'100%', borderRadius: '8px' }} />
        </Col>
        <Col xs={24} md={16}>
            <Card>
              <Title level={4}>Unlock New Markets & Revenue Streams: Powerful Licensing & Distribution Deals</Title>
              <Paragraph>
              
              </Paragraph>
              <Paragraph>
              Expand your reach and profits with expertly crafted licensing and distribution agreements. Whether you're the licensor or distributor, we'll ensure your deal protects your interests and fuels growth.
              </Paragraph>
              <Paragraph>Focus on your core business, I'll handle the legalese. With our experience, you can confidently enter into lucrative and secure licensing and distribution agreements.</Paragraph>
              
            </Card>
            </Col>
        </Row>
        <Card>
              <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Our expertise helps you win:</span>              
              </Paragraph>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
               <Card title="Ironclad Agreements" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>We'll tailor a watertight agreement that outlines all rights, obligations, and royalties, leaving no room for ambiguity.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card  title="IP Protection Powerhouse" className="matter-container-itemlist-card custom-card-header" > 
                <Paragraph>Your intellectual property is safe. We'll safeguard your assets and ensure proper licensing or distribution.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card title="Territory Takeover" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>Maximize your reach with strategic territorial restrictions and exclusivity arrangements, crafted to your needs.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card title="Compliance Guaranteed" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>Relax, we'll ensure the agreement meets all legal and industry standards.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card title="Negotiation Ninja" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>Get the best deal possible. we'll fight for favorable terms that maximize your revenue and market share.
                </Paragraph>
               </Card>
                </Col>
                <Col xs={24} md={8}>
               <Card title="Future-Proofed Agreements" className="matter-container-itemlist-card custom-card-header" >
                <Paragraph>The business landscape evolves. we'll draft agreements that adapt to your growth and changing needs.
                </Paragraph>
               </Card>
                </Col>
                </Row>
              </Card>
      </div>
  </Layout>
);

export default Licensing;
