import React from 'react';
import { Layout, Typography, Divider } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => (
  <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
    <Content style={{ padding: '50px 100px', backgroundColor: '#fff', margin: '50px auto', maxWidth: '1000px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Title level={2} style={{ textAlign: 'center' }}>Privacy Policy</Title>
      <Divider />
      <Paragraph>
        This website is designed to provide information about our services, to collect information from those interested in contacting us for those services, and to enable our lawyers to contact potential clients. This privacy policy applies only to the online collection of information from users of this website.
      </Paragraph>
      <Title level={3}>Personal Information</Title>
      <Paragraph>
        Personal information means any information about or that can be used to identify you as an individual, including the matter about which you contacted this website and any other information that you choose to provide. In most cases, personal information does not include information available in public records, nor does it include corporate information such as your business address or telephone number in your capacity as an employee of an organization.
      </Paragraph>
      <Title level={3}>Canadian Privacy Principles</Title>
      <Paragraph>
        We follow ten principles established by the Personal Information Protection and Electronic Documents Act when collecting, using or disclosing your personal information. These principles include accountability, identifying the purpose of the information collected, obtaining your consent, limiting the personal information collected, using it only for the intended purpose, ensuring the information is accurate, safeguarding the privacy of your information, being transparent about our policies and practices, providing you with access to your information upon request, and addressing your concerns.
      </Paragraph>
      <Title level={3}>Consent</Title>
      <Paragraph>
        By providing us with your personal information, you consent to the collection, use and disclosure of that information as described in this Policy. We will not require you to consent to the collection, use or disclosure of your personal information beyond that required to enable us to provide the requested services.
      </Paragraph>
      <Title level={3}>Information Collection</Title>
      <Paragraph>
        We collect information in three ways: directly from you, from our web server logs, and through cookies. If you fill out the contact form or participate in an online chat, we will ask for personal information such as your email address, name, phone number, and province. Please do not submit any confidential or sensitive personally identifiable information.
      </Paragraph>
      <Title level={3}>Web Server Logs</Title>
      <Paragraph>
        Certain information about Internet users’ traffic patterns is collected automatically and linked to users’ Internet Protocol addresses. Our servers automatically log information about visits to our website, such as a visitor’s IP address, type of operating system, time and duration of visit, and web pages requested. We do not link server log information to any other data in a way that would enable us to identify individual visitors, except for security purposes.
      </Paragraph>
      <Title level={3}>Cookies</Title>
      <Paragraph>
        Our site uses “per session” cookie technology, which contains a unique identification number automatically deposited on a visitor’s computer to track information during an open session. This information helps us determine the total number of visitors to the site on an ongoing basis and the types of Internet browsers and operating systems our visitors use. We do not cross-reference this information with any type of personal information that is voluntarily offered through the site.
      </Paragraph>
      <Title level={3}>Use and Disclosure of Personal Information</Title>
      <Paragraph>
        We use personal information only as described in this Policy or as otherwise permitted or required by law. We share any information you submit with our lawyers and persons working with them to determine if the legal services of our member lawyers are right for you. We may also use your personal information to send you newsletters about our services or about legal developments that we think would interest you. We may disclose your information to service providers inside or outside Canada retained by us to assist us with marketing or other services. If we are unable to assist with your matter, but know an unaffiliated attorney or firm that may be able to help you, we may refer you and share information you provided us with that party.
      </Paragraph>
      <Title level={3}>Retention and Protection of Personal Information</Title>
      <Paragraph>
        We retain personal information for as long as necessary for the purposes for which it was collected. We use physical, organizational, and technological measures to protect the security of personal information in our possession.
      </Paragraph>
      <Title level={3}>Access to Personal Information</Title>
      <Paragraph>
        You may ask to view your personal information and request that corrections be made where the information we possess is inaccurate. Please note that under certain circumstances, we may not be able to provide access to certain personal information.
      </Paragraph>
    </Content>
  </Layout>
);

export default PrivacyPolicy;
