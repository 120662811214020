import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { NumberOutlined, FileTextOutlined, SearchOutlined, DollarOutlined, InsuranceOutlined, IssuesCloseOutlined, FileProtectOutlined,  TransactionOutlined,  ProfileOutlined, EnvironmentOutlined } from '@ant-design/icons';
import LandlordTenantImage from '../../assets/litigation-upscaled.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const ResidentialLandlordTenantDisputes = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Residential Landlord and Tenant Disputes:</Title>
    <div className="matter-container">
      <Row gutter={[16,16]}>
      <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Landlord or Tenant Issue? Get Clear Guidance & Fast Resolutions.</Title>
            <Paragraph>
              Renting can be complicated. Whether you're a landlord facing a lease violation or a tenant facing eviction, we'll protect your rights and navigate the legalese.
            </Paragraph>
            <Paragraph>
              My expertise keeps things smooth:
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Landlords:</span> Enforce your lease, handle property damage, and navigate evictions with confidence.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Tenants:</span> Know your rights, fight unfair treatment, and avoid eviction.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Fast & Effective Solutions:</span> We'll work tirelessly to resolve disputes efficiently, saving you time and money.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your peace of mind,</span> we'll handle the landlord-tenant law. With our experience, you can approach any rental situation with clarity and confidence.
            </Paragraph>
            </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <img src={LandlordTenantImage} alt="Civil Litigation" style={{  width: '100%' }} />
        </Col>
      </Row>
      <Card>

            
            <Row gutter={[16,16]}>
            <Col xs={24} sm={12} md={6}>
                <Card title={<><NumberOutlined /> 1. Expert Representation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Expert representation in residential landlord and tenant disputes.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileTextOutlined /> 2. Lease Violations</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Handling of lease violations, non-payment of rent, and property damage claims.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><SearchOutlined /> 3. Defense Against Eviction</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Defense against eviction proceedings and wrongful eviction claims.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><DollarOutlined /> 4. Dispute Settlement</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Negotiation and settlement of disputes to avoid litigation when possible.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><InsuranceOutlined /> 5. Filing Documents</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Preparation and filing of eviction notices and court documents.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><IssuesCloseOutlined /> 6. Court Representation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Representation in eviction proceedings and landlord-tenant court hearings.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileProtectOutlined /> 7. Tenant Rights</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Protection of tenant rights under relevant residential/housing laws.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><TransactionOutlined /> 8. Landlord Rights</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Enforcement of landlord rights to collect rent and maintain property standards.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><EnvironmentOutlined /> 9. Lease Drafting</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Assistance with lease drafting and review to prevent future disputes.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><ProfileOutlined /> 10. Comprehensive Understanding</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Comprehensive understanding of landlord-tenant law and regulatory requirements.</Paragraph>
                </Card>
              </Col>
            </Row>
          </Card>
        
    </div>
  </Layout>
);

export default ResidentialLandlordTenantDisputes;
