import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import {
  NumberOutlined,
  FileTextOutlined,
  SearchOutlined,
  DollarOutlined,
  InsuranceOutlined,
  IssuesCloseOutlined,
  FileProtectOutlined,
  TransactionOutlined,
  ProfileOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import CommercialRealEstateImage from '../../assets/commercial.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const CommercialRealEstate = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Commercial Real Estate:</Title>
    <div className="matter-container">
      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <img src={CommercialRealEstateImage} alt="Commercial Real Estate" style={{ height: '100%', width: '100%', borderRadius: '8px' }} />
        </Col>
        <Col xs={24} md={16}>
          <Card>
            <Title level={4}>Commercial Real Estate. Secured. Streamlined.</Title>
            <Paragraph>
              Turn your commercial property vision into reality with expert legal guidance every step of the way.
            </Paragraph>
            <Paragraph>
              We'll help you:
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Close Deals with Confidence:</span> From buying and selling to leasing and development, we'll negotiate strong contracts that protect your interests.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Diligence Done Right:</span> Avoid surprises. We'll conduct thorough due diligence to ensure a smooth transaction.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Dispute Resolution Pro:</span> Facing a hurdle? We'll navigate disagreements and find solutions that benefit you.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Seamless Closings:</span> Relax, we'll handle the legalese and ensure a smooth closing process.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your business goals,</span> I'll handle the legalese. With my experience in commercial real estate law, you can achieve your property goals with confidence and security.
            </Paragraph>
          </Card>
        </Col>
      </Row>
      <Card>
        <Paragraph>
          <span className='content-sub-minititle-withoutbg-different-textcolor'>Our expertise helps you win:</span>
        </Paragraph>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={6}>
            <Card title={<><NumberOutlined /> Assistance with Buying or Selling</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Assistance with buying, selling, leasing, or developing commercial properties.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title={<><FileTextOutlined /> Contract Negotiation</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Negotiation and drafting of commercial real estate contracts and agreements.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title={<><SearchOutlined /> Due Diligence</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Conducting due diligence investigations to assess property risks and liabilities.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title={<><DollarOutlined /> Reviewing Title Documents</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Reviewing title documents, surveys, and zoning regulations.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title={<><InsuranceOutlined /> Financing Options</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Advising on financing options and structuring real estate transactions.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title={<><IssuesCloseOutlined /> Dispute Resolution</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Resolving disputes related to commercial property ownership or lease agreements.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title={<><FileProtectOutlined /> Commercial Lease Agreements</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Drafting and reviewing commercial lease agreements and tenant contracts.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title={<><TransactionOutlined /> Property Transfers</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Facilitating property transfers, closings, and title insurance matters.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title={<><EnvironmentOutlined /> Environmental Regulations</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Providing guidance on environmental regulations and land use planning.</Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title={<><ProfileOutlined /> Property Management</>} className="matter-container-itemlist-card custom-card-header">
              <Paragraph>Offering legal support for property management and landlord-tenant issues.</Paragraph>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  </Layout>
);

export default CommercialRealEstate;
