// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import AppHeader from './components/Header/Header';
import FooterComponent from './components/Footer/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import BusinessLaw from './pages/BusinessLaw';
import RealEstate from './pages/Real-Estate';
import Litigation from './pages/Litigation';
import WillsEstate from './pages/WillsEstate';
import Notary from './pages/Notary';
import Team from './pages/Team';
import Blog from './pages/Blog';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';
import TermsAndConditions from './pages/TermsAndConditions';
// busines submenu
import Incorporations from './pages/businessLaw/Incorporations';
import Partnership from './pages/businessLaw/Partnership';
import BusinessPurchase from './pages/businessLaw/BusinessPurchase';
import CommercialLease from './pages/businessLaw/CommercialLease';
import ContractNegotiation from './pages/businessLaw/ContractNegotiation';
import EmploymentContracts from './pages/businessLaw/EmploymentContracts';
import Trademark from './pages/businessLaw/Trademark';
import Confidentiality from './pages/businessLaw/Confidentiality';
import Franchising from './pages/businessLaw/Franchising';
import Licensing from './pages/businessLaw/Licensing';
import ServiceSupply from './pages/businessLaw/ServiceSupply';
import ResidentialRealEstate from './pages/realEstate/ResidentialRealEstate';
import CommercialRealEstate from './pages/realEstate/CommercialRealEstate';
import MobileNotary from './pages/notary/MobileNotary';


const App = () => (
  <Router>
    <Layout>
      <AppHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/about-us/team" element={<Team />} />
        <Route path="/about-us/blog" element={<Blog />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/about-us/contact" element={<ContactUs />} />
        <Route path="/BusinessLaw" element={<BusinessLaw />} />
        <Route path="/business-law/incorporations" element={<Incorporations />} />
        <Route path="/business-law/partnership" element={<Partnership />} />
        <Route path="/business-law/business-purchase" element={<BusinessPurchase />} />
        <Route path="/business-law/commercial-lease" element={<CommercialLease />} />
        <Route path="/business-law/licensing" element={<Licensing />} />
        <Route path="/business-law/contract-negotiation" element={<ContractNegotiation />} />
        <Route path="/business-law/franchising" element={<Franchising />} />
        <Route path="/business-law/employment-contracts" element={<EmploymentContracts />} />
        <Route path="/business-law/trademark" element={<Trademark />} />
        <Route path="/business-law/confidentiality" element={<Confidentiality />} />
        <Route path="/business-law/service-supply" element={<ServiceSupply />} />
        <Route path="/real-estate/residential" element={<ResidentialRealEstate />} />
        <Route path="/real-estate" element={<RealEstate />} />
        <Route path="/real-estate/commercial" element={<CommercialRealEstate />} />
        <Route path="/Litigation" element={<Litigation />} />
        <Route path="/litigation/civil" element={<Litigation />} />
        <Route path="/litigation/adr" element={<Litigation />} />
        <Route path="/litigation/business" element={<Litigation />} />
        <Route path="/litigation/ip" element={<Litigation />} />
        <Route path="/litigation/landlord-tenant" element={<Litigation />} />
        <Route path="/litigation/banking" element={<Litigation />} />
        <Route path="/litigation/estate" element={<Litigation />} />
        <Route path="/wills-estate/wills" element={<WillsEstate />} />
        <Route path="/wills-estate/power-of-attorney" element={<WillsEstate />} />
        <Route path="/wills-estate/probate" element={<WillsEstate />} />
        <Route path="/notary" element={<Notary />} />
        <Route path="/WillsEstate" element={<WillsEstate />} />
      </Routes>
      <FooterComponent />
    </Layout>
  </Router>
);

export default App;
