import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { NumberOutlined, FileTextOutlined, SearchOutlined, DollarOutlined, InsuranceOutlined, IssuesCloseOutlined, FileProtectOutlined } from '@ant-design/icons';
import BusinessLitigationImage from '../../assets/litigation-upscaled.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const BusinessLitigation = () => (
  <Layout>
    <Title level={2} style={{ marginTop: '50px' }}>Business Litigation:</Title>
    <div className="matter-container">
    <Row gutter={[16,16]}>
    <Col xs={24} sm={12} md={8}>
          <img src={BusinessLitigationImage} alt="Business Litigation" style={{  width: '100%' }} />
        </Col>
        <Col xs={24} sm={12} md={16}>
          <Card>
            <Title level={4}>Business Dispute? Get Powerful Advocacy & Strategic Solutions.</Title>
            <Paragraph>
              Don't let business disagreements disrupt your success. We'll be your relentless advocate, navigating complex commercial disputes to achieve favorable outcomes.
            </Paragraph>
            <Paragraph className='content-minititle-withbg'>
              My expertise protects your business:
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Contract Conflicts: Breached agreements?</span>  We'll fight for what's rightfully yours.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Shareholder Showdowns: Internal disputes?</span> We'll help find solutions that benefit all parties.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Employment Issues: Facing employee lawsuits?</span> We'll safeguard your interests.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>IP Infringement: Protecting your ideas is crucial.</span> We'll defend your intellectual property.
            </Paragraph>
            <Paragraph>
            <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on running your business,</span>
               we'll handle the legal battle. With our experience in business litigation, you can face any dispute with confidence and clarity.
            </Paragraph>
            </Card>
            </Col>
            </Row>
            <Row gutter={[16,16]}>
              <Col xs={24} sm={12} md={24}>
              <Card>
            
            <Row gutter={[16,16]}>
            <Col xs={24} sm={12} md={6}>
                <Card title={<><NumberOutlined /> 1. Expert Representation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Expert representation in a wide range of business litigation matters.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileTextOutlined /> 2. Strategic Legal Advice</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Skilled advocacy and strategic legal advice tailored to your specific case.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><SearchOutlined /> 3. Contract Disputes</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Handling of contract disputes, breach of fiduciary duty claims, and shareholder disputes.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><DollarOutlined /> 4. Commercial Tort Claims</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Defense against commercial tort claims, including fraud and unfair competition allegations.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><InsuranceOutlined /> 5. Negotiation and Settlement</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Negotiation and settlement of disputes to avoid costly litigation when possible.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><IssuesCloseOutlined /> 6. Trial Preparation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Preparation for trial and aggressive courtroom advocacy when litigation is unavoidable.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <Card title={<><FileProtectOutlined /> 7. Best Outcomes</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Commitment to achieving the best possible outcome for our business clients.</Paragraph>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
        
      </Row>
    </div>
  </Layout>
);

export default BusinessLitigation;
