import React from 'react';
import { Layout, Col, Row, Typography, Card } from 'antd';
import { CheckCircleOutlined, SolutionOutlined, SwapOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import BusinessImage from '../../assets/business.jpg';
import '../../styles/CommonStyle.css';

const { Title, Paragraph } = Typography;

const BusinessPurchase = () => (
  <Layout>
    <Title className='component-title' level={2} style={{ marginTop: '50px' }}>Business Purchase & Sale:</Title>
    <div className="matter-container">
    <Row gutter={[16, 16]}>
    <Col xs={24} md={8}>
          <img src={BusinessImage} alt="Business" style={{  width: '100%', borderRadius: '8px' }} />
        </Col>
        <Col  xs={24} md={16}>
          <Card>
            <Title level={4}>Buying or selling a business? We make it secure and maximize your value.</Title>
            <Paragraph>
              Don't let legal complexities hold you back. Whether you're buying or selling, we'll guide you through every step, ensuring a smooth and successful transaction.
            </Paragraph>
            <Paragraph>
            <span className='content-minititle-withbg'>Our expertise helps you achieve your goals:</span>
            </Paragraph>
            <ul>
              <li><span className='content-sub-minititle-withoutbg'>Seamless Navigation:</span> We'll handle all the legal legwork, leaving you free to focus on your business goals.</li>
              <li><span className='content-sub-minititle-withoutbg'>In-Depth Due Diligence:</span> We'll meticulously analyze the business to uncover potential issues and secure the best deal for you.</li>
              <li><span className='content-sub-minititle-withoutbg'>Expert Negotiations:</span> We'll fight for the best terms, maximizing your value and protecting your interests.</li>
            </ul>        
            </Card>
        </Col>        
      </Row>
      <Card>
            <Paragraph>
              <span className='content-sub-minititle-withoutbg-different-textcolor'>Focus on your goals,</span> we'll handle the legalese. With our experience, you can buy or sell your business with confidence.
            </Paragraph>
            <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
                <Card title={<><CheckCircleOutlined /> Seamless Navigation</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Handle all legal legwork, leaving you to focus on business goals.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title={<><SolutionOutlined /> In-Depth Due Diligence</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Analyze the business to uncover issues and secure the best deal.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title={<><SwapOutlined /> Expert Negotiations</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Fight for the best terms, maximizing your value and protecting your interests.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title={<><SafetyCertificateOutlined /> Legal Compliance</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Ensure the transaction meets all legal requirements, giving you peace of mind.</Paragraph>
                </Card>
              </Col>
              <Col xs={24} md={8}>
                <Card title={<><CheckCircleOutlined /> Tailored Solutions</>} className="matter-container-itemlist-card custom-card-header">
                  <Paragraph>Customize the process to perfectly fit your unique business needs.</Paragraph>
                </Card>
              </Col>
            </Row>
            </Card>
    </div>
  </Layout>
);

export default BusinessPurchase;
