import React, { useState } from 'react';
import { Layout, Row, Col, Form, Input, Button, Card, Modal } from 'antd';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import map01 from '../../assets/map_address_01.png';
const { Footer } = Layout;

const FooterComponent = () => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('Received values:', values);
        form.resetFields();
        setModalVisible(true); // Show congratulations modal
        // Handle form submission logic (e.g., API call)
      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
      });
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  // Google Maps URL with latitude and longitude (replace with your desired coordinates)
  const googleMapsUrl =
    'https://www.google.com/maps/place/1035+McNicoll+Ave,+Toronto,+ON+M1W+3W6,+Canada/@43.803999,-79.3342653,150m/data=!3m1!1e3!4m6!3m5!1s0x89d4d3a072170925:0xa2c299c29eb74ebb!8m2!3d43.8040196!4d-79.3343891!16s%2Fg%2F11fy964kry?entry=ttu';

  return (
    <Footer style={{ background: '#fff', padding: '20px' }}>
      <Row gutter={[16, 16]}>
        {/* Addresses and Map (Placeholder Image) */}
        <Col xs={24} sm={24} md={12}>
          <h3>Addresses and Map</h3>
          <div style={{ width: '100%', height: '300px', backgroundColor: '#f0f0f0', borderRadius: '8px', marginBottom: '16px' }}>
            {/* Placeholder image or illustration */}
            <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
              <img src={map01} alt="Map Placeholder" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} /> 
            </a>
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}>
              <Card title="Address 01">
                <p><EnvironmentOutlined style={{ fontSize: '24px', marginRight: '10px' }} /> 1035 McNicoll Avenue</p>
                <p>Toronto, ON M1W 3W6</p>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Card title="Address 02">
                <p><EnvironmentOutlined style={{ fontSize: '24px', marginRight: '10px' }} /> 53 Telegraph Drive</p>
                <p>Whitby, ON L1P 1S3</p>
              </Card>
            </Col>
          </Row>
        </Col>

        {/* Contact Us Form */}
        <Col xs={24} sm={24} md={12}>
          <h3>Contact Us</h3>
          <Form form={form} layout="vertical" name="contactForm" onFinish={handleFormSubmit}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter your name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please enter your email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="message"
              label="Message"
              rules={[{ required: true, message: 'Please enter your message' }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" className='banner-button' htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {/* Social Icons */}
      <Row justify="center" style={{ marginTop: '30px' }}>
        <Col>
          <FacebookOutlined className='content-sub-minititle-withoutbg-different-textcolor' style={{ fontSize: '24px', marginRight: '10px' }} />
          <TwitterOutlined className='content-sub-minititle-withoutbg-different-textcolor' style={{ fontSize: '24px', marginRight: '10px' }} />
          <InstagramOutlined className='content-sub-minititle-withoutbg-different-textcolor' style={{ fontSize: '24px', marginRight: '10px' }} />
        </Col>
      </Row>

      {/* Menu Items: Terms & Conditions and Privacy Policy */}
      <Row justify="center" style={{ marginTop: '30px' }}>
        <Col>
          <Link to="/TermsAndConditions" className='content-sub-minititle-withoutbg-different-textcolor'>Terms & Conditions </Link>
        </Col>
        <Col>
          <Link to="/PrivacyPolicy" className='content-sub-minititle-withoutbg-different-textcolor'> | Privacy Policy</Link>
        </Col>
      </Row>

      {/* Congratulations Modal */}
      <Modal
        title="Congratulations!"
        visible={modalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="ok" type="primary" onClick={closeModal}>
            OK
          </Button>,
        ]}
      >
        <p>Your message has been successfully submitted.</p>
      </Modal>
    </Footer>
  );
};

export default FooterComponent;
